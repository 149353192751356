<template>
    <div class="buySharePage">
        <!-- <div class="title">
            <img @click="goBack" src="../asset/images/fanhui.png" alt="">
            {{commodityData.branchName}}
        </div> -->
        <img class="poster" :src="commodityData.picUrl" alt="">
        <div class="content">
            <div class="name">{{commodityData.goodsName}}</div>
            <div :class="['btn', commodityData.isUse?'isUsed':'']" @click="consume">立即领取</div>
            <p class="tips">小贴士：领取后立即生效，不支持退换</p>
        </div>
    </div>
</template>

<script>
export default {
    name:'buySharePage',
    data(){
        return{
            token: '',
            commodityData: {},
            flag: true,
            uuid: '',
            branchId: ''
        }
    },
    mounted(){
        this.token = localStorage.getItem('token')
        this.uuid = this.getQueryVariable('uuid')
        this.branchId = this.getQueryVariable('branchId')
        this.consumeOrderDetailInfo()
    },
    methods:{
        getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                return pair[1];
                }
            }
            return false;
        },
        goBack() {
            this.$router.go(-1);
        },
        consumeOrderDetailInfo() {
            this.$api.jzHome.consumeOrderDetailInfo(this.uuid).then(res => {
                if(res.code == 0 && res.data){
                    this.commodityData = res.data;
                    document.title = this.commodityData.branchName;
                }
            })
        },
        consume() {
            if (this.commodityData.isUse) {
                this.$toast('剩余数量为0，无法领取');
            } else {
                if (this.token) {
                    if (this.flag) {
                        this.flag = false;
                        this.$api.jzHome.consume(this.uuid).then(res => {
                            if (res.code == 0 && res.data) {
                                this.$toast(res.data.alertMessage);
                                setTimeout(() => {
                                    this.$router.replace({
                                        path:'/goodsDetail',
                                        query: {
                                            goodsId: res.data.distributionGoodsId,
                                            goodsKind: res.data.goodsType// 1 商品 2 分销商品
                                        }
                                    })
                                }, 500)
                            }
                        })
                        setTimeout(() => {
                            this.flag = true;
                        }, 500)
                    }
                } else {
                    if (this.uuid) {
                        this.$router.replace({
                            path:'/login',
                            query:{
                                name:'buySharePage',
                                uuid: this.uuid,
                                branchId: this.branchId
                            }
                        })
                    }
                }
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
    .buySharePage
        .title
            text-align: center
            font-size: 36px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            height: 88px;
            line-height: 88px;
            box-shadow: 0px 0px 0px 0px #EAEAEA;
            position relative
            img
                position absolute
                top 50%
                left: 24px;
                transform: translateY(-50%)
                width 54px
        .poster
            display: block;
            width: 100%;
        .content
            margin: 32px;
            .name
                font-size: 36px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 64px;
            .btn
                width: 100%;
                height: 88px;
                background: #0089FF;
                border-radius: 6px;
                text-align: center;
                line-height: 88px;
                font-size: 30px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            .isUsed
                background: rgba(0,137,255,0.3);
            .tips
                margin-top 32px
                text-align: center;
                font-size: 26px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
</style>