<template>
    <div class="homeCertificate">
        <div class="contentCard">
            <div class="up">
                <img src="../asset/images/up.png" alt="">
            </div>
            <div class="middle">
                <div class="avatar">
                    <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/junmin/3.png" alt=""> -->
                    <img :src="housekeepingStaffInfo.headImg" alt="">
                </div>
                <div class="userInfo">
                    <div class="name">
                        <span class="title justify" v-html="'姓        名：'"></span>
                        <span class="val">{{ housekeepingStaffInfo.name }}</span>
                    </div>
                    <div class="birthday">
                        <span class="title">出生日期：</span>
                        <span class="val">{{ housekeepingStaffInfo.birthday || '--'}}</span>
                    </div>
                    <div class="number">
                        <span class="numberval">证书编号：{{ housekeepingStaffInfo.certificateNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="down">
                <div v-show="firstFlag" ref="qrCodeDiv" style="width:180px;height:180px;margin:0 auto"></div>
                <div v-show="!firstFlag" style="width:180px;height:180px;margin:0 auto">
                    <img style="width:100%" :src="canvasImageUrl" alt="">
                </div>
                <div class="tip">扫码查看电子信息</div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
export default {
    name:'homeCertificate',
    data(){
        return{
            firstFlag:true,
            canvasImageUrl:'',
            housekeepingStaffInfo:{}
        }
    },
    async mounted(){
        document.title = '居家上门服务证'
        // await this.getDetail()
        this.qrcode()
        this.createPicture() // 二维码生成后，执行生成图片
    },
    methods:{
        // getDetail(){
        //     return this.$api.jzHome.getCertificate().then(res => {
        //         if(res.code == 0 && res.data){
        //             this.housekeepingStaffInfo = res.data
        //             this.housekeepingStaffInfo.birthday = this.housekeepingStaffInfo.birthday && this.housekeepingStaffInfo.birthday.slice(0,10)
        //         }
        //     })
        // },
        qrcode() {
            new QRCode(this.$refs.qrCodeDiv, {
                width: 180,
                height: 180,
                text: window.location.origin+'/certificateDetail?id='+this.housekeepingStaffInfo.id, // 二维码地址
            });
        },
        createPicture(){
            html2canvas(this.$refs.qrCodeDiv,{
                width: 180,
                height: 180
            }).then(canvas => {
                this.canvasImageUrl = canvas.toDataURL("image/png")
                this.firstFlag = false
            }).catch(error => {})
        }
    }
}
</script>

<style lang="stylus" scoped>
    .homeCertificate
        min-height 100%
        background: url('../asset/images/bg.png') no-repeat center; 
        background-size cover 
    @import '../asset/css/homeCertificate.styl'
</style>