<template>
    <div class="home-box">
        <div v-if="show" class="top-box">
            <div class="head">
                <van-dropdown-menu active-color="#1989fa" style="width: 100%;">
                    <van-dropdown-item @change="institutionClick" v-model="branchId" :options="branchList" :title="branchName" ></van-dropdown-item>
                </van-dropdown-menu>
            </div>

            <banner/>
          <template v-if="!isRchz">
            <!-- 立即学习 -->
            <div class="zs-block">
                <div class="title" v-if="!certificate">
                    获取证书流程
                </div>
                <div class="title" v-else>
                    我的上门证
                    <span class="more" @click="toCer"><strong>查看</strong><van-icon name="arrow" /></span>
                </div>
                <div class="body">
                    <img class="info-img" @click="openDiolg" v-if="!certificate" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/jiazheng/zslc@2x.png" />
                    <div class="info" v-else>
                        <div class="left-img"><img :src="certificate.headImg"/></div>
                        <div class="right-content">
                            <p><strong>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</strong>{{certificate.name}}</p>
                            <p><strong>出生日期：</strong>{{certificate.birthday?certificate.birthday.substring(0,10):''}}</p>
                            <div>证书编号：{{certificate.certificateNumber}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="zs-block">
                <div class="title" style="border:0;margin-bottom:0;">
                    学习上门证
                </div>
                <div class="img-box" @click="openDiolg">
                    <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/jiazheng/smz.png"/>
                </div>
            </div>
          </template>
        </div>

        <div v-else class="empty">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/home/img%402x.png" alt="">
            <p class="text">暂无机构</p>
        </div>
        <van-dialog v-model="isshow" width="295" title="选择申请居家上门服务证的公司" :show-confirm-button="false">
            <van-radio-group v-model="radio">
                <van-cell-group>
                    <van-cell v-for="item in certificateUserBranchList" :key="item.branch" :title="item.name" clickable @click="chooseBranch(item)">
                    <template #right-icon>
                        <van-radio :name="item.branch" />
                    </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
            <div class="vanbtn-box">
                <div class="cancel-vanbtn" @click="cancel">取消</div>
                <div class="sure-vanbtn" @click="sure">确定</div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
    import banner from '../block/banner.vue'
    import moment from "moment";
    import {Notify} from 'vant'
    // import liveListEntry from '../../live/page/liveListEntry.vue';

    export default {
        name: 'jz_homepage',
        components: {banner},
        data() {
            return {
                isshow:false,
                isadd:false,
                branchId: null,
                branchName: null,
                branchList: [],
                certificate: null,
                show: true,
                exam:null,
                isapp:false,
                certificateUserBranchList:[],
                theme: '',
                ishavezs:true,
                radio:'',
                nowBranch:{},
                task:{},
                isRchz: false,
            }
        },
        async created() {
            // url里有rchz-h5的时候，isRchz为true
            if (window.location.href.indexOf('rchz-h5') > -1) {
              this.isRchz = true;
            }
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('ezhouxing') != -1;
            if (!isWeixin) {
                this.isapp=true;
            }else{
                this.isapp=false;
            }
        },
        async mounted () {
            this.theme = sessionStorage.getItem('theme')
            await this.getFindUserBranchList()
            await this.init()
        },

        beforeDestroy() {
            clearInterval(this._onlineTimer);
        },
        filters: {
            dataFormat(val) {
              if (val) {
                return moment(val).format("YYYY.MM.DD HH:mm");
              }
            }
        },
        methods: {
            async init () {
                await this.findDoorCertificateUserBranchList()
                if( !this.isRchz ){
                  // await this.getCertificate();
                }
            },
            async institutionClick(value) {
                this.branchList.forEach(async item => {
                    if (item.value == value) {
                        this.isadd = false
                        this.branchId = item.value
                        this.branchName = item.text
                        localStorage.setItem('code', item.code)
                        localStorage.setItem('organ', item.organ)
                        localStorage.setItem('actor', item.actor)
                        localStorage.setItem('branch', item.value)
                        item.channel && localStorage.setItem('channel', item.channel)
                        setTimeout(async () => {
                            if( !this.isRchz ){
                              // this.getCertificate();
                            }
                            this.getAccountUserInfo();
                            this.isadd = true
                        }, 500)
                    }
                })
            },
            async getFindUserBranchList () {
                this.getAccountUserInfo()
                return this.$api.account.getFindUserBranchList({}).then(res => {
                    if (res.data && res.data.length) {
                        this.branchList = res.data.filter(item => Object.prototype.toString.call(item) === '[object Object]').map(item => {
                            return {
                                text: item.name,
                                value: item.branch,
                                actor: item.actor,
                                organ: item.organ,
                                channel: item.channel,
                                code: item.code
                            }
                        })
                        this.show = true
                        if (!this.branchList.length) return
                        localStorage.setItem('branchList', JSON.stringify(this.branchList))
                        if (localStorage.getItem('branch')) {
                            res.data.forEach(item => {
                                if (item.branch == localStorage.getItem('branch')) {
                                    this.branchId = item.branch;
                                    this.branchName = item.name
                                }
                            })
                        } else {
                            this.branchId = res.data[0].branch;
                            this.branchName = res.data[0].name
                        }
                        !localStorage.getItem('code') && localStorage.setItem('code', res.data[0].code)
                        !localStorage.getItem('organ') && localStorage.setItem('organ', res.data[0].organ)
                        !localStorage.getItem('actor') && localStorage.setItem('actor', res.data[0].actor)
                        !localStorage.getItem('branch') && localStorage.setItem('branch', res.data[0].branch)
                        !localStorage.getItem('channel') && res.data[0].channel && localStorage.setItem('channel', res.data[0].channel)
                        this.$sensors.login(res.data[0].code)
                        this.isadd=true;
                    } else {
                        this.show = false
                    }
                })
            },
            async getAccountUserInfo () {
                return this.$api.usercenter.getAccountUserInfo({}).then(res => {
                    if (res.data) {
                        this.$store.state.userInfo = {
                            ...this.$store.state.userInfo,
                            ...res.data
                        }
                        localStorage.setItem('WDuserInfo', JSON.stringify(this.$store.state.userInfo))
                    }
                })
            },
            // async getCertificate() {
            //     return await this.$api.jzHome.getCertificate().then(res => {
            //         if(res.code==0) {
            //             this.certificate = res.data
            //         }
            //     });
            // },
            //获取
            async findDoorCertificateUserBranchList(){
                return await this.$api.jzHome.findDoorCertificateUserBranchList({}).then(res => {
                    if(res.code==0) {
                        this.certificateUserBranchList = res.data;
                        if(res.data&&res.data.length){
                            this.radio = res.data[0].branch;
                            this.nowBranch = res.data[0];
                        }
                    }
                });
            },
            async getTaskList(){
                return await this.$api.jzHome.getTaskList().then(res => {
                    if(res.code==0) {
                        if(res.data.list&&res.data.list.length>0){
                            this.task = res.data.list[0];
                            this.$router.push({
                                path:"/learning/Detailstrain",
                                query:{
                                    id: this.task.id,
                                    backType:1
                                }
                            })
                        }
                    }
                });
            },
            openDiolg(){
                Notify({ type: 'danger', message: '请前往咚咚燕助手家政员端学习查看居家上门服务证相关内容' });
                // if(this.certificateUserBranchList&&this.certificateUserBranchList.length>0){
                //     this.isshow = true;
                // }else{
                //     Notify({ type: 'danger', message: '请联系所在机构，为您申请上门服务证' });
                // }
            },
            cancel(){
                this.isshow = false;
            },
            async sure(){
                if(this.task){
                    if(this.radio){
                        this.branchId = this.nowBranch.branch
                        this.branchName = this.nowBranch.name
                        await this.institutionClick(this.branchId);
                        setTimeout(async () => {
                            this.getTaskList();
                        },501)
                    }else{
                        Notify({ type: 'danger', message: '请选择申请居家上门服务证的公司' });
                    }
                }
            },
            chooseBranch(data){
                this.nowBranch = data;
                this.radio = data.branch;
            },
            toCer(){
                Notify({ type: 'danger', message: '请前往咚咚燕助手家政员端学习查看居家上门服务证相关内容' });
                // this.$router.push({
                //     path:"/homeCertificate"
                // })
            }
        }
    }
</script>
<style lang="stylus" scoped>
    .home-box
        height: 100vh;
        background: #f9f9f9;

        .van-dropdown-menu__bar
            box-shadow 0 0 0 0
            position: relative!important;
            z-index: 100!important;
            background: #f9f9f9!important;

        .van-dropdown-menu__item
            position relative

        .van-dropdown-menu__title--active, .van-dropdown-menu__title
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: bold;
            color: #000000;
            position absolute
            left 15px
            top 13px
        /deep/.van-popup--top
            top:48px

        .van-dropdown-menu__title::after
            // left 0
            right:-10px
            top:14px
            border:0
            width:11px
            height:11px
            background-size:100%
            background-image url('../asset/down.png')
            background-repeat no-repeat
            transform rotate(0)

        .van-dropdown-menu__title--active::after
            background-image url('../asset/up.png')
            top 9px
</style>
<style lang="stylus" scoped>
    .home-box
        >>>.van-dropdown-menu__bar
            box-shadow 0 0 0 0
            position: relative!important;
            z-index: 100!important;
            background: #f9f9f9!important;
        .vanbtn-box
            display flex
            justify-content space-between
            width 250px;
            margin 5px auto 24px;
            div.cancel-vanbtn
                width: 118px;
                height: 40px;
                border-radius: 22px;
                border: 1px solid #3860FF;
                font-size: 16px;
                color: #3860FF;
                line-height: 40px;
                text-align center;
            div.sure-vanbtn
                width: 118px;
                height: 40px;
                border-radius: 22px;
                border: 1px solid #3860FF;
                background: linear-gradient(136deg, #227FFF 0%, #316FFF 100%);
                font-size: 16px;
                color: #ffffff;
                line-height: 40px;
                text-align center;
        >>>.van-dialog__header
                font-size: 18px;
                font-weight: 600;
                color: #202124;
                line-height: 18px;
                padding:32px 0 24px;
        >>>.van-dialog__content
                width:100%;
                .van-radio-group
                    max-height: 120px;
                    overflow: auto;
                .van-cell__title
                    text-align :left;
                    font-size: 16px;
                    color: #202124;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                .van-cell
                    padding:0 22px 23px;
                .van-cell::after
                    content:none;
                .van-hairline--top-bottom:after
                    content:none;
        .head
            display flex
            margin-bottom:9px;
            .returnBtn
                flex 1
                height 2.56rem
                font-size 14px
                line-height 2.56rem
                margin-right 0.8rem
                text-align right
                color rgb(55, 96, 255)
                font-weight bold
    .top-box
        background: #F9F9F9;
        padding-bottom:130px;
    .empty
        margin-top 300px
        width 100%
        text-align center

        img
            width 450px

        .text
            margin-top 80px
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 40px;

    .institution
        width 100%
        height 100px

        p
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
            line-height 100px
            box-sizing border-box
            padding 0 39px 0 46px
            width 100%
            height 100%

            &.active
                background #EEF2FF
                color: #3760FF;
            .choose
                position relative
                top 40px
                width 32px
                float right
// 学习流程
    .zs-block
        width 690px
        margin 0 auto 30px
        // margin-bottom 80px
        background :#ffffff;
        border-radius: 16px;
        .img-box
            padding:0 30px 32px;
            img
                width 100%;
                height :470px;
        .title
            font-weight bold
            font-size: 34px;
            color: #333333;
            line-height: 34px;
            margin 0 30px 38px;
            padding:42px 0 30px;
            border-bottom:2px solid #F0F0F0;
            position :relative;
            .more
                position :absolute;
                right:0px;
                top:40px;
                font-size: 26px;
                color: #666666;
                line-height :26px;
                strong
                    font-weight:normal;
                    vertical-align :middle;
                .van-icon-arrow
                    font-size :12px;
                    vertical-align :middle;
        .body
            width: 100%;
            // height: 150px;
            // background: #F8F8FA;
            // padding 30px 20px
            box-sizing border-box
            border-radius: 16px;
            position relative
            .info-img
                width 638px
                height 152px
                margin:0 auto;
                display: block;
                padding-bottom:48px;
                // margin-right 20px
                // vertical-align top
                // border-radius: 16px;
            .info
                display flex
                padding :0 30px 32px;
                .left-img
                    width:176px;
                    height:176px;
                    margin-right:32px;
                    overflow: hidden;
                    border-radius: 0.64rem;
                    flex-shrink:0;
                    img
                        width:100%;
                        // height:176px;
                        // border-radius: 24px;
                .right-content
                    // overflow:hidden;
                    div
                        font-size: 22px;
                        font-weight: 400;
                        color: #FFFFFF;
                        background: #D49A6E;
                        border-radius: 20px;
                        padding:4px 16px;
                        display:flex;
                        align-items:center;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                    p
                        margin-bottom :20px;
                        font-size: 32px;
                        font-weight: 400;
                        color: #333333;
                        strong
                            font-size: 32px;
                            font-weight: 400;
                            color: #BBBBBB;
                            line-height: 44px;


</style>
