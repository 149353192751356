<template>
    <div class="swiper-wrap">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div ref="swiperSlide"  class="swiper-slide" v-for="item in imgList" :key="item.id">
                    <!-- 具体内容 -->
                    <img :src="item.url" @click="toDetail(item)"/>
                </div>
            </div>
            <div class="swiper-pagination" />
        </div>
    </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
    name:'banner',
    props:{
        imgList:{
            type:Array,
            default:() => [
                {
                    id:1,
                    url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/jiazheng/banner.png'
                }
            ]
        }
    },
    data(){
        return {
            swiper:null,
        }
    },
    created(){
        let host = window.location.host
        if (host.includes('nmjz')) {
            let obj={
                id:2,
                url:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/jiazheng/banner2.jpg'
            }
            this.imgList.push(obj);
        }
    },
    mounted(){
        this.initSwiper()
    },
    methods:{
         initSwiper() {
            // let selfthis = this;
            this.swiper = new Swiper(".swiper-container", {
                loop: true,
                autoplay: true,
                spaceBetween: 15,
                observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        },
        toDetail(item){
            if(this.$route.name=='junmin_homepage'&&item.zyUrl){
                // window.location.href = item.zyUrl;
                this.$router.push({
               path:item.zyUrl
             })
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.swiper-wrap
    width 690px
    margin 0 auto 30px
    .swiper-pagination-bullet-active
        background #fff
    .swiper-slide
        width: 100%;
        img 
            width 100%
            border-radius 24px
    // .swiper-slide-active
    //     margin-right: 30px
    //     margin-left: 30px;
    // .swiper-slide-prev
    //     margin: 0 0 0 -30px;


</style>