<template>
    <div class="homeCertificate">
        <div class="contentCard">
            <div class="up">
                <img src="../asset/images/up.png" alt="">
                <div class="status">
                    <img v-if="housekeepingStaffInfo.certificateStatus == 2" src="../asset/images/yx.png" alt="">
                    <img v-else-if="housekeepingStaffInfo.certificateStatus == 3" src="../asset/images/sx.png" alt="">
                </div>
            </div>
            <div class="middle">
                <div class="avatar">
                    <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/junmin/3.png" alt=""> -->
                    <img :src="housekeepingStaffInfo.headImg" alt="">
                </div>
                <div class="userInfo">
                    <div class="name">
                        <div class="title justify" v-html="'姓        名：'"></div>
                        <!-- <span class="title justify">姓名<span style="letter-spacing:">：</span></span> -->
                        <div class="val">{{ housekeepingStaffInfo.name }}</div>
                    </div>
                    <div class="birthday">
                        <div class="title">出生日期：</div>
                        <div class="val">{{ housekeepingStaffInfo.birthday || '--'}}</div>
                    </div>
                    <div class="number">
                        <span class="numberval">证书编号：{{ housekeepingStaffInfo.certificateNumber }}</span>
                    </div>
                </div>
            </div>
            <div class="detailInfo">
                <!-- <div class="item">
                    <span class="title">信用等级：</span>
                    <div class="val firstVal">
                        <ul class="star">
                            <li v-for="(item,index) in 5" :key="index">
                                <img v-if="index+1 <= housekeepingStaffInfo.creditScoreLevel" src="../asset/images/star1.png" alt="">
                                <img v-else src="../asset/images/star2.png" alt="">
                            </li>
                        </ul>
                        <span class="grade" v-if="housekeepingStaffInfo.creditScoreLevel">{{ housekeepingStaffInfo.creditScoreLevel }}级</span>
                    </div>
                </div> -->
                <div class="item">
                    <div class="title justify" v-html="'姓        名：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.name }}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'工        种：'"></div>
                    <div class="val">{{ workType }}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'性        别：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.gender || '--' }}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'民        族：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.nation || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'籍        贯：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.householdRegister || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'学        历：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.workerEducation || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify" v-html="'婚        姻：'"></div>
                    <div class="val">{{ housekeepingStaffInfo.maritalStatus || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify">出生日期：</div>
                    <div class="val">{{ housekeepingStaffInfo.birthday || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify">身份证号：</div>
                    <div class="val">{{ housekeepingStaffInfo.idCard || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify">证件编号：</div>
                    <div class="val">{{ housekeepingStaffInfo.certificateNumber }}</div>
                </div>
                <div class="item">
                    <div class="title justify">现在住址：</div>
                    <div class="val">{{ housekeepingStaffInfo.currentAddress || '--'}}</div>
                </div>
                <div class="item">
                    <div class="title justify">所属企业：</div>
                    <div class="val">{{ housekeepingStaffInfo.affiliatedCompanies }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'certificateDetail',
    data(){
        return{
            housekeepingStaffInfo:{},
            workType: '--'
        }
    },
    mounted(){
        document.title = '居家上门服务证' 
        this.getDetail()
    },
    methods:{
       getDetail(){
            this.$api.jzHome.getCertificateByqrcode(this.$route.query.id).then(res => {
                if(res.code == 0 && res.data){
                    this.housekeepingStaffInfo = res.data
                    this.housekeepingStaffInfo.birthday = this.housekeepingStaffInfo.birthday && this.housekeepingStaffInfo.birthday.slice(0,10)
                    var workTypeArr = [];
                    if (this.housekeepingStaffInfo.workTypeList.length > 0) {
                        if (this.housekeepingStaffInfo.workTypeList.length == 1 && this.housekeepingStaffInfo.workTypeList[0].name == '其他') {
                            this.workType = '家政员';
                        } else {
                            this.housekeepingStaffInfo.workTypeList.forEach(item=>{
                                workTypeArr.push(item.name)
                            })
                            this.workType = workTypeArr.join('、');
                        }
                    }
                }
            })
        },
    }
}
</script>

<style lang="stylus" scoped>
    .homeCertificate
        min-height 100%
        background: url('../asset/images/bg.png') no-repeat center; 
        background-size cover 
    @import '../asset/css/homeCertificate.styl'
</style>